import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import OrderInfoPurchase from "./order-info.purchase";

const FinalConfirm = ({ purchaseData, productsData }) => {
  const columns = [
    {
      id: "prod_id",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "prod_nombre",
      label: "Producto",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "suppliers_name",
      label: "Proveedores",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name_medida",
      label: "Medida",
      minWidth: 60,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "qtypaied_pupro",
      label: "Total a comprar",
      minWidth: 60,
      align: "left",
      input: "number",
    },
    {
      id: "pricepaied_pupro",
      label: "Total a pagar",
      minWidth: 80,
      align: "left",
      input: "text",
    }
  ];

  return (
    <div className="w-full">
      <div className="text-center">
        <h1 className="text-2xl font-bold text-purple-700">
          ¡Felicitaciones acabamos de terminar el proceso de compra!
        </h1>
      </div>

      <OrderInfoPurchase
        selectedInfo={{
          codigo: purchaseData?.code_purchase,
          priority: purchaseData?.priority_purchase,
          purchaseDate: purchaseData?.date_purchase,
          description: purchaseData?.description_purchase,
          amountpaidreceipt: purchaseData?.amountpaidreceipt_purchase,
          datepaidreceipt: purchaseData?.datepaid_purchase,
        }}
        isComponentFinal={true}
      />

      <div className="p-2">
        <h3 className="text-sm font-semibold">Productos comprados</h3>
      </div>

      <ContainerInput className="p-0">
        <CustomTable
          columns={columns}
          rows={productsData}
          load={false}
          error={false}
          pagination={false}
        />
      </ContainerInput>
    </div>
  );
};

export default FinalConfirm;
