import "../carta.style.scss";

import { Button, Container, Grid } from "@mui/material";

import { Call, Room, WhatsApp } from "@mui/icons-material";

// import PropTypes from "prop-types";

const FooterCarta = (props) => {
  let { data, name = "", direction = "", comuna = "" } = props;

  const handleRedirect = (link) => {
    window.open(link, "_blank");
  };

  const listButtons = [
    {
      title: "LLAMAR",
      icon: <Call />,
      link: `tel:${data.telf_empsede}`,
    },
    {
      title: "WHATSAPP",
      icon: <WhatsApp />,
      link: data.wsp_empsede,
    },
    {
      title: "UBICACIÓN",
      icon: <Room />,
      link: data.ubication_empsede,
    },
  ];

  const nameCompany = data?.nom_empsede
    ? `${name} - ${data?.nom_empsede}`
    : name;

  return (
    <div className="footer" style={{ backgroundColor: data.color_primary }}>
      <div className="title__footer">
        <strong>{nameCompany}</strong> <br />
        {direction && (
          <>
            {direction.toUpperCase()} <br />
          </>
        )}
        {comuna && (
          <>
            {comuna.toUpperCase()} <br />
          </>
        )}
      </div>

      <Container fixed>
        <Grid container spacing={2}>
          {listButtons.map((item, index) => {
            return (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <Button
                  variant="outlined"
                  fullWidth
                  className="button__footer"
                  onClick={() => handleRedirect(item.link)}
                >
                  {item.icon} {item.title}
                </Button>
              </Grid>
            );
          })}
        </Grid>
        <div className="copyright__footer">© Copyright Equipo Ubuntu.</div>
      </Container>
    </div>
  );
};

// FooterCarta.propTypes = {
//   name: PropTypes.element.isRequired,
//   direction: PropTypes.element.isRequired,
//   comuna: PropTypes.element.isRequired,
// };

export default FooterCarta;
