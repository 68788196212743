import axios from "axios";
import { config } from "../config";

import { axiosHeader } from "../functions/axiosHeader";

const { baseurl } = config;

export const get_clientes = (id) => {
  const url = `${baseurl}/api/clientes/get/`;

  return axios
    .get(
      url,
      axiosHeader({
        empsede: id,
      })
    )
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const get_clientes_id = (id) => {
  const url = `${baseurl}/api/clientes/get/${id}`;

  return axios
    .get(url, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const search_cliente = (params) => {
  const url = `${baseurl}/api/clientes/search/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_clientes = (params) => {
  const url = `${baseurl}/api/clientes/add/`;

  return axios
    .post(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const update_cliente = (params, idx) => {
  const url = `${baseurl}/api/clientes/update/${idx}`;

  return axios
    .put(url, params, axiosHeader())
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_clientes_carta = (params) => {
  const url = `${baseurl}/api/clientes/add_carta/`;

  return axios
    .post(url, params)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};

export const add_client_public = (params) => {
  const url = `${baseurl}/api/clientes/add_public/`;

  return axios
    .post(url, params)
    .then((response) => response.data.body)
    .catch((err) => {
      throw new Error(err);
    });
};
