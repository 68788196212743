import axios from "axios";
import { config } from "../config";

const { apiKeyYT } = config;

export const videoSearch = async (query) => {
  try {
    const apiKey = apiKeyYT;
    const url = `https://www.googleapis.com/youtube/v3/search?part=snippet&q=${query}&type=video&key=${apiKey}`;

    const response = await axios.get(url);

    const data = response.data;

    if (data.items && data.items.length > 0) {
      console.log("data", data);
      const videoId = data.items[0].id.videoId;
      const description = data.items[0].snippet.title;

      return {
        embed: `https://www.youtube.com/embed/${videoId}`,
        watch: `https://www.youtube.com/watch?v=${videoId}`,
        description,
        videoId,
      };
    }

    return null;
  } catch (error) {
    console.error("Error searching for video:", error);

    return null;
  }
};
