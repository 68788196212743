/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Template from "../../components/Template";

import ContainerInput from "../../components/ContainerInput";
// import LoadingPage from "../../components/LoadingPage";

import CustomTable from "../../atomic/organisms/Table.organisms";
import { get_supplier } from "../../services/supplier.services";
import FilterSupplier from "./ui/filters.supplier";
import ModalCrudSupplier from "./ui/modalcrud.supplier";

const Supplier = () => {
  const [load, setLoad] = useState(false);
  const [dataSupplier, setDataSupplier] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [dataSelected, setDataSelected] = useState(null);

  const [openCud, setOpenCud] = useState(false);

  const onSearchData = async (value) => {
    setDataSupplier({ load: true, err: false, data: [] });
    const response = await get_supplier();

    let filterData = response;

    if (value?.searchName) {
      filterData = filterData.filter((el) => {
        return (
          el.name_supplier
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.rut_supplier
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.contact_supplier
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.mail_supplier
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.address_supplier
            .toLowerCase()
            .includes(value.searchName.toLowerCase())
        );
      });
    }

    setDataSupplier({ load: false, err: false, data: filterData });
  };

  async function handleGetData() {
    try {
      setDataSupplier({ load: true, err: false, data: [] });
      const response = await get_supplier();
      setDataSupplier({ load: false, err: false, data: response });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleGetData();
  }, []);

  return (
    <Template title="Proveedores">
      <FilterSupplier
        onSearchData={onSearchData}
        onLoadData={load}
        onOpenAddModal={setOpenCud}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "name_supplier",
              label: "Nombre",
              minWidth: 80,
              align: "left",
            },
            {
              id: "rut_supplier",
              label: "RUT",
              minWidth: 80,
              align: "left",
            },
            {
              id: "category_supplier",
              label: "Categoría",
              minWidth: 100,
              align: "left",
            },
            {
              id: "contact_supplier",
              label: "Contacto",
              minWidth: 80,
              align: "left",
            },
            {
              id: "telf_supplier",
              label: "Teléfono",
              minWidth: 80,
              align: "left",
            },
            {
              id: "mail_supplier",
              label: "Email",
              minWidth: 80,
              align: "left",
            },
            {
              id: "address_supplier",
              label: "Dirección",
              minWidth: 80,
              align: "left",
            },
            {
              id: "observations_supplier",
              label: "Observaciones",
              minWidth: 80,
              align: "left",
            }
          ]}
          rows={dataSupplier.data}
          load={dataSupplier.load}
          error={false}
          pagination={true}
          actionEdit={(element) => {
            setDataSelected(element);
            setOpenCud(true);
          }}
          actionDelete={(element) => {
            setDataSelected({ delete: true, ...element });
            setOpenCud(true);
          }}
        />
      </ContainerInput>

      <ModalCrudSupplier
        dataSelected={dataSelected}
        clearSelected={() => setDataSelected(null)}
        open={openCud}
        setOpen={setOpenCud}
        callBack={() => {
          handleGetData();
        }}
      />
    </Template>
  );
};

export default Supplier;
