/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Template from "../../components/Template";

import ContainerInput from "../../components/ContainerInput";
// import LoadingPage from "../../components/LoadingPage";

import dayjs from "dayjs";
import CustomTable from "../../atomic/organisms/Table.organisms";
import { getPurchase } from "../../services/purchase.services";
import FilterPurchase from "./ui/filter.purchase";
import ModalPurchase from "./ui/modal.purchase";
import ModalFinalPurchase from "./ui/modal-final.purchase";
import ModalSeePurchase from "./ui/modal-see.purchase";

const Purchase = () => {
  const [dataPurchase, setDataPurchase] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [dataSelected, setDataSelected] = useState(null);

  const [openCrud, setOpenCrud] = useState(false);
  const [openSeePurchase, setOpenSeePurchase] = useState(false);
  const [openFinalCrud, setOpenFinalCrud] = useState(false);

  const handleSearchPurchase = async (value) => {
    const response = await fetchData();

    if (response.length === 0) {
      return;
    }

    let filterData = response;

    if (value?.searchName) {
      filterData = filterData.filter((el) => {
        return el.cod_purchase
          .toLowerCase()
          .includes(value.searchName.toLowerCase());
      });
    }

    if (value?.dateRange.length > 0) {
      filterData = filterData.filter((el) => {
        const date = dayjs(el?.createdat_purchase).utc();

        return (
          date.isAfter(dayjs(value.dateRange[0]).utc()) &&
          date.isBefore(dayjs(value.dateRange[1]).utc())
        );
      });
    }

    setDataPurchase({ load: false, err: false, data: filterData });
  };

  const handleOpenFinalCrud = (code) => {
    const findPurchase = dataPurchase.data.find(
      (el) => el.purchase.code_purchase === code
    );

    setDataSelected(findPurchase);

    const isFinalized = findPurchase.purchase?.status_purchase === "Finalizado";

    if (isFinalized) {
      setOpenSeePurchase(true);
      return;
    }

    setOpenFinalCrud(true);
  };

  async function fetchData() {
    try {
      setDataPurchase({ ...dataPurchase, load: true, data: [], err: false });

      const response = await getPurchase();

      setDataPurchase({
        ...dataPurchase,
        load: false,
        data: response,
        err: false,
      });

      return response;
    } catch (err) {
      setDataPurchase({ ...dataPurchase, load: false, data: [], err: true });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Compras">
      <FilterPurchase
        onLoadData={dataPurchase.load}
        onSearchData={handleSearchPurchase}
        onOpenAddModal={setOpenCrud}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "code_purchase",
              label: "Código",
              minWidth: 30,
              align: "left",
              format: (el) => {
                return (
                  <div
                    className="text-blue-500 cursor-pointer hover:underline flex align-middle items-center"
                    onClick={() => handleOpenFinalCrud(el)}
                  >
                    #{el}
                  </div>
                );
              },
            },
            {
              id: "number_events",
              label: "N° de eventos",
              minWidth: 30,
              align: "left",
            },
            {
              id: "number_products",
              label: "N° de productos",
              minWidth: 30,
              align: "left",
            },
            {
              id: "status_purchase",
              label: "Estado",
              minWidth: 80,
              align: "left",
              format: (el) => {
                if (el === "En proceso") {
                  return (
                    <div className="text-xs bg-orange-500 w-28 rounded p-1 text-center text-white">
                      {el}
                    </div>
                  );
                }

                if (el === "Finalizado") {
                  return (
                    <div className="text-xs bg-green-500 w-28 rounded p-1 text-center text-white">
                      {el}
                    </div>
                  );
                }

                if (el === "Cancelado") {
                  return (
                    <div className="text-xs bg-red-500 w-28 rounded p-1 text-center text-white">
                      {el}
                    </div>
                  );
                }

                if (el === "Creado") {
                  return (
                    <div className="text-xs bg-blue-500 w-28 rounded p-1 text-center text-white">
                      {el}
                    </div>
                  );
                }
              },
            },
            {
              id: "usu_nombre",
              label: "Responsable",
              minWidth: 80,
              align: "left",
            },
            {
              id: "createdat_purchase",
              label: "Fecha de creación",
              minWidth: 80,
              align: "left",
              format: (el) => {
                return el && dayjs(el).format("DD/MM/YYYY HH:mm");
              },
            },
          ]}
          rows={dataPurchase.data.map((el) => ({
            ...el.purchase,
          }))}
          load={dataPurchase.load}
          error={false}
          pagination={true}
          // actionEdit={(element) => {
          //   setOpenCrud(true);

          //   setDataSelected(
          //     dataPurchase.data.find(
          //       (el) => el.purchase.id_purchase === element?.id_purchase
          //     )
          //   );
          // }}
          // actionDelete={(element) => {
          //   setOpenCrud(true);
          //   setDataSelected({ ...element, delete: true });
          // }}
        />
      </ContainerInput>

      <ModalPurchase
        open={openCrud}
        setOpen={setOpenCrud}
        callBack={() => {
          fetchData();
        }}
      />

      <ModalFinalPurchase
        dataSelected={dataSelected}
        setDataSelected={setDataSelected}
        clearSelected={() => setDataSelected(null)}
        open={openFinalCrud}
        setOpen={setOpenFinalCrud}
        callBack={() => {
          fetchData();
        }}
      />

      <ModalSeePurchase
        dataSelected={dataSelected}
        clearSelected={() => setDataSelected(null)}
        open={openSeePurchase}
        setOpen={setOpenSeePurchase}
        callBack={() => {
          fetchData();
        }}
      />
    </Template>
  );
};

export default Purchase;
