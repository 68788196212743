import React, { useEffect } from "react";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import io from "socket.io-client";
import "./assets/styles/Main.scss";

import zhES from "rsuite/locales/es_ES";

import RenderLogin from "./pages/auth/index";

import RenderCapacidad from "./pages/capacidad/index";
import RenderHome from "./pages/home/index";
import RenderUser from "./pages/user/index";
import RenderSettingProfile from "./pages/user/settingProfile";
import RenderChangePassword from "./pages/user/setttingChangePassword";

import RenderCompanies from "./pages/settings/company/index.company";
import RenderSucursal from "./pages/sucursales";
import RenderMetaMerchantes from "./pages/metaMerchantes/index";

import RenderGarzones from "./pages/garzones/index";
import RenderMesas from "./pages/mesa/index";
import RenderPlazas from "./pages/plazas/index";
import RenderPlazaSelected from "./pages/plazas/PlazaSelected";

import RenderHistorialCliente from "./pages/historialCliente/index";

import RenderAddReserva from "./pages/reservas/add_reserva";
import RenderReservas from "./pages/reservas/index";
import RenderPanelReserva from "./pages/reservas/panel_reserva";
import RenderPanelFinal from "./pages/reservas/finalReserva";

import Reserva_cancelar from "./pages/reservas/Reserva_cancelar";
// import Reserva_final from "./pages/reservas/Reserva_final";

import RenderMenu from "./pages/menu";

import RenderMenuCategories from "./pages/carta/Categories/index";
import RenderCartaSecondary from "./pages/carta/Categories/CartaSecondary";
import RenderMenuCheckout from "./pages/carta/Checkout/index";
import RenderMenuPedido from "./pages/carta/Pedido/index";
import RenderMenuProducts from "./pages/carta/Products/index";

import RenderCategoriaProducto from "./pages/categoria/index";

import RenderCloseBox from "./pages/closebox";
import RenderOrder from "./pages/orders";
import RenderOrderCreate from "./pages/orders/Crear";
import RenderCompletedOrders from "./pages/orders/pedidosTerminados";
import RenderOrderView from "./pages/orders/Ver";

import RenderChecker from "./pages/checker";
import RenderCheckerSecondary from "./pages/checkerSecondary";

import RenderCookOrder from "./pages/cookorders";
import RenderCookOrderHistory from "./pages/cookordersHistory";
import RenderImprimirComandas from "./pages/orders/printPedidos";
import RenderSoldOut from "./pages/soldOut/soldout.view";

import RenderActivarGarzones from "./pages/activarGarzones";
import RenderAssignTable from "./pages/mesasGarzones";
import RenderPlatosSoldOut from "./pages/platosSoldOut";

import RenderAnalytic from "./pages/analytic";
import RenderSettingsReporte from "./pages/report";
import RenderSettingsReservationSellers from "./pages/reservationSellers";
import RenderPAXProductivity from "./pages/paxProductivity";
import RenderSurvey from "./pages/survey/survey";
// import RenderTicket from "./pages/ticket/ticket.page";
import RenderCampaign from "./pages/campaign/campaign.page";

import RenderBenefit from "./pages/benefit/index.benefit";
import RenderClientHistory from "./pages/clientHistory/index.clientHistory";
import RenderMyTickets from "./pages/myTickets/index.myTickets";
import RenderOrderHistory from "./pages/orderHistory/index.orderHistory";
import RenderTicketBenefit from "./pages/ticketBenefit/index.ticketBenefit";
import RenderQuotation from "./pages/quotation/index.quotation";
import RenderPlanning from "./pages/planning/index.planning";
import RenderPlanningId from "./pages/planning/id.planning";
import RenderFinancePlanningId from "./pages/planning/finance.planning";

import RenderConsumption from "./pages/consumption/index.consumption";
import RenderMyReservationClient from "./pages/myReservations/client.myreservations";
import RenderMyReservation from "./pages/myReservations/index.myreservations";
import RenderMyReservationStatus from "./pages/myReservations/status.myreservations";

import RenderTasks from "./pages/tasks";
import RenderIdQuotation from "./pages/quotation/id.quotation";

import RenderForgotPassword from "./pages/auth/forgot-password";
import RenderResetPassword from "./pages/auth/reset-password";

import RenderWaller from "./pages/wallet/index.wallet";
import RenderWallerHistory from "./pages/walletHistory/index.wallet-history";
import RenderPayMerchante from "./pages/payMerchante/index.pay-merchante";
import RenderDeliveryProducts from "./pages/deliveryProducts/index.delivery";

import RenderSettingsHome from "./pages/settings/home/index.settings-home";

import RenderProducts from "./pages/productsv2/index.products";
import RenderSupplier from "./pages/supplier/index.supplier";
import RenderPurchase from "./pages/purchase/index.purchase";

import RenderDjSuggestionsClient from "./pages/dj-suggestions/client.dj-suggestions";
import RenderDjSuggestions from "./pages/dj-suggestions/index.dj-suggestions";

/*
  PROVIDER
*/

import PrivateRoute from "./provider/PrivateRoute";
import PublicRoute from "./provider/PublicRoute";

// import { updateTokenUser } from "./functions/tokenFirebase.function";

import { HelmetProvider } from "react-helmet-async";

import { Provider } from "react-redux";

import store from "./store";

import { config } from "./config";
import { IntlProvider } from "react-intl";
import { CustomProvider } from "rsuite";
import ContractDH from "./components/PDFDocuments/contract.pdfdocument";
// import { getUser } from "./services/auth.services";

const options = {
  rememberUpgrade: true,
  transports: ["websocket"],
  secure: true,
  rejectUnauthorized: false,
};

const newSocket = io.connect(config.baseurl, options);

const App = () => {
  // useEffect(() => {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");
  //       updateTokenUser();
  //     } else {
  //       console.log("Unable to get permission to notify.");
  //     }
  //   });
  // }, []);

  useEffect(() => {
    newSocket.on("connect", function () {
      console.log("* Connected to server.");
    });

    return () => newSocket.close();
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <IntlProvider locale="es">
          <CustomProvider locale={zhES}>
            <HelmetProvider>
              <Switch>
                <PublicRoute
                  path="/"
                  component={RenderLogin}
                  restricted={true}
                  exact
                />
                {/* <PublicRoute
                path="/ticket/:id_campaign"
                component={RenderTicket}
                restricted={false}
                exact
              /> */}
                <PrivateRoute path="/campaign" component={RenderCampaign} />
                <PublicRoute
                  path="/carta/:empresa"
                  component={RenderMenuCategories}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/carta-secondary/:empresa"
                  component={RenderCartaSecondary}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/carta/:empresa/:id"
                  component={RenderMenuProducts}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/carta/:empresa/checkout"
                  component={RenderMenuCheckout}
                  restricted={false}
                  exact
                  socket={newSocket}
                />
                <PublicRoute
                  path="/carta/:empresa/pedido/:id_pedido"
                  component={RenderMenuPedido}
                  restricted={false}
                  exact
                  socket={newSocket}
                />
                <PublicRoute
                  path="/reservar/:empresa"
                  component={RenderPanelReserva}
                  restricted={false}
                  exact
                />
                <PublicRoute
                  path="/reservar/final/:empresa"
                  component={RenderPanelFinal}
                  restricted={false}
                  exact
                />

                <PublicRoute
                  path="/reservar/:empresa/:sucursal"
                  component={RenderAddReserva}
                  restricted={false}
                  exact
                />
                {/* <PublicRoute
              path="/reserva_final/:empresa/:sucursal/:estado/:pax"
              component={Reserva_final}
              restricted={false}
              exact
            /> */}
                <PublicRoute
                  path="/cancelar_reserva/:id"
                  component={Reserva_cancelar}
                />
                <PrivateRoute
                  path="/home"
                  component={RenderHome}
                  socket={newSocket}
                />
                <PrivateRoute path="/reserva" component={RenderReservas} />
                <PrivateRoute
                  path="/menu_mant/capacidad"
                  component={RenderCapacidad}
                />
                <PrivateRoute
                  path="/menu_mant/garzones"
                  component={RenderGarzones}
                />
                <PrivateRoute
                  path="/menu_mant/plaza/:id"
                  component={RenderPlazaSelected}
                />
                <PrivateRoute path="/menu" component={RenderMenu} />
                <PrivateRoute path="/menu_mant/mesas" component={RenderMesas} />
                <PrivateRoute
                  path="/menu_mant/plazas"
                  component={RenderPlazas}
                />
                <PrivateRoute
                  path="/historialClientes"
                  component={RenderHistorialCliente}
                />
                <PrivateRoute
                  path="/orders/view/:id_pedido"
                  component={RenderOrderView}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/orders/add/:id_pedido"
                  component={RenderOrderCreate}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/cookorders"
                  component={RenderCookOrder}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/cookordersHistory"
                  component={RenderCookOrderHistory}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/orders"
                  component={RenderOrder}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/completeOrders"
                  component={RenderCompletedOrders}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/closebox"
                  component={RenderCloseBox}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/checker"
                  component={RenderChecker}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/checkerSecondary"
                  component={RenderCheckerSecondary}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/printComandas"
                  component={RenderImprimirComandas}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/soldout"
                  component={RenderSoldOut}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/garzonesActive"
                  component={RenderActivarGarzones}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/platosSoldOut"
                  component={RenderPlatosSoldOut}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/assignTable"
                  component={RenderAssignTable}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/clientHistory"
                  component={RenderClientHistory}
                />
                <PrivateRoute
                  path="/quotation/:cod"
                  component={RenderIdQuotation}
                />
                <PrivateRoute path="/quotation" component={RenderQuotation} />
                <PrivateRoute
                  path="/planning/finance/:cod"
                  component={RenderFinancePlanningId}
                />
                <PrivateRoute
                  path="/planning/:cod"
                  component={RenderPlanningId}
                />
                <PrivateRoute path="/planning" component={RenderPlanning} />
                <PrivateRoute
                  path="/orderHistory"
                  component={RenderOrderHistory}
                />
                <PrivateRoute
                  path="/consumption"
                  component={RenderConsumption}
                />
                <PrivateRoute
                  path="/benefit/:id"
                  component={RenderTicketBenefit}
                />
                <PrivateRoute path="/benefit" component={RenderBenefit} />
                <PrivateRoute path="/myTickets" component={RenderMyTickets} />
                <PrivateRoute
                  path="/report"
                  component={RenderSettingsReporte}
                />
                <PrivateRoute path="/analytic" component={RenderAnalytic} />
                <PrivateRoute path="/tasks" component={RenderTasks} />
                <PrivateRoute path="/wallet" component={RenderWaller} />
                <PrivateRoute
                  path="/wallet-history"
                  component={RenderWallerHistory}
                />
                <PrivateRoute
                  path="/pay-merchante"
                  component={RenderPayMerchante}
                />
                <PrivateRoute
                  path="/delivery-products"
                  component={RenderDeliveryProducts}
                />
                <PrivateRoute
                  path="/products"
                  component={RenderProducts}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/purchase"
                  component={RenderPurchase}
                  socket={newSocket}
                />
                <PrivateRoute
                  path="/supplier"
                  component={RenderSupplier}
                  socket={newSocket}
                />
                <PublicRoute
                  path="/forgot-password"
                  component={RenderForgotPassword}
                />
                <PublicRoute
                  path="/reset-password/:token"
                  component={RenderResetPassword}
                />
                <PublicRoute
                  path="/my-reservations/:id"
                  component={RenderMyReservationClient}
                />
                <PublicRoute
                  path="/my-reservations"
                  component={RenderMyReservation}
                />
                <PublicRoute
                  path="/my-reservation/:status/:id_emp/:id_reserva"
                  component={RenderMyReservationStatus}
                />
                <PublicRoute
                  path="/survey/:id_pedido"
                  component={RenderSurvey}
                />
                <PrivateRoute
                  path="/product-category"
                  component={RenderCategoriaProducto}
                />
                <PrivateRoute
                  path="/settings/home"
                  component={RenderSettingsHome}
                />
                <PrivateRoute
                  path="/settings/report"
                  component={RenderSettingsReporte}
                />
                <PrivateRoute
                  path="/settings/reservation-sellers"
                  component={RenderSettingsReservationSellers}
                />
                <PrivateRoute
                  path="/settings/pax-productivity"
                  component={RenderPAXProductivity}
                />
                <PrivateRoute path="/settings/users" component={RenderUser} />
                <PrivateRoute
                  path="/settings/my-profile"
                  component={RenderSettingProfile}
                />
                <PrivateRoute
                  path="/settings/change-password"
                  component={RenderChangePassword}
                />
                <PrivateRoute
                  path="/settings/companies"
                  component={RenderCompanies}
                />
                <PrivateRoute path="/contract" component={ContractDH} />
                <PrivateRoute
                  path="/settings/sucursales"
                  component={() =>
                    RenderSucursal({
                      type: "normal",
                    })
                  }
                />
                <PrivateRoute
                  path="/settings/sucursales-colegio"
                  component={() =>
                    RenderSucursal({
                      type: "colegio",
                    })
                  }
                />
                <PrivateRoute
                  path="/settings/merchant-goal"
                  component={RenderMetaMerchantes}
                />
                <PublicRoute
                  path="/dj-suggestions/:companyId"
                  component={RenderDjSuggestionsClient}
                />
                <PrivateRoute
                  path="/dj-suggestions"
                  component={RenderDjSuggestions}
                  socket={newSocket}
                />

                <Route path="*">
                  <h1>404 Not fo und</h1>
                </Route>
              </Switch>
            </HelmetProvider>
          </CustomProvider>
        </IntlProvider>
      </Router>
    </Provider>
  );
};

export default App;
