import { MusicNote } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import InputDefault from "../../atomic/atoms/input.atoms";
import LoadingPage from "../../components/LoadingPage";
import PublicTemplate from "../../components/Template/PublicTemplate";
import { useDebounce } from "../../hooks/useDebounce";
import { createSuggestDj } from "../../services/suggest-dj.services";
import { videoSearch } from "../../services/youtube.services";

const RenderDjSuggestionsClient = (props) => {
  const { companyId } = useParams();

  const [loading, setLoading] = useState(false);
  const [loadingVideo, setLoadingVideo] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: {
      musicName: "",
      linkMusic: "",
      descriptionMusic: "",
      companyId: Number(companyId),
      videoId: "",
      musicDescription: "",
    },
  });

  const musicName = watch("musicName");
  const debouncedQuery = useDebounce(musicName, 900);

  // Function to search for a video
  const searchVideo = async (query) => {
    if (!query) {
      setVideoUrl(null);
      return;
    }

    try {
      setLoadingVideo(true);
      const { embed, watch, description, videoId } = await videoSearch(query);
      setLoadingVideo(false);

      if (embed && embed !== "") {
        setVideoUrl(embed);

        setValue("linkMusic", watch);
        setValue("musicDescription", description);
        setValue("videoId", videoId);
      } else {
        setVideoUrl(null);

        setValue("linkMusic", "");
        setValue("musicDescription", "");
        setValue("videoId", "");
      }
    } catch (error) {
      console.error("Error searching for video:", error);
      setVideoUrl(null);
    }
  };

  // Trigger video search whenever the debounced query changes
  useEffect(() => {
    if (!debouncedQuery) {
      setVideoUrl(null);
      return;
    }

    searchVideo(debouncedQuery);
  }, [debouncedQuery]);

  const handleSave = async (data) => {
    const cartUser = JSON.parse(localStorage.getItem("cart_user"));
    const userId = cartUser?.id || null;

    try {
      setLoading(true);
      await createSuggestDj({ ...data, userId });
      reset();
      setLoading(false);
      setCompleted(true);
    } catch (err) {
      console.log(err);
    }
  };

  if (completed) {
    return (
      <PublicTemplate
        title="Sugiere al DJ"
        companyId={companyId && Number(companyId)}
        headActived={false}
        footerActived={false}
      >
        <div className="flex flex-col items-center justify-center h-screen">
          <h2 className="text-center text-2xl font-bold text-slate-700">
            <MusicNote className="mr-2 text-red-500" />
            Felicidades, tu música fue añadida correctamente a la lista del DJ.
            <MusicNote className="ml-2 text-red-500" />
          </h2>

          <div className="mt-2 text-center text-sm text-slate-500">
            Para volver añadir tienes que esperar 1 hora.
          </div>
        </div>
      </PublicTemplate>
    );
  }

  return (
    <PublicTemplate
      title="Sugiere al DJ"
      companyId={companyId && Number(companyId)}
    >
      {loading && <LoadingPage open={true} />}

      <Alert severity="info" className="mt-10">
        <span className="font-bold">
          <span className="text-red-500">*</span> Recuerda buscar correctamente
          la música, el sistema buscará en Youtube y te mostrará el video, si ya
          tienes el enlace de Youtube, remplaza el texto con el enlace.
        </span>
      </Alert>

      <form
        onSubmit={handleSubmit(handleSave)}
        autoComplete="off"
        className="mb-20"
      >
        <div className="mb-4 mt-4 w-full grid grid-cols-12 gap-3">
          <div className="col-span-12">
            <h1 className="mb-4">
              <span className="text-1xl font-bold text-slate-700">
                Sugerir al DJ:
              </span>
            </h1>
          </div>
          <div className="col-span-12">
            <Controller
              name="musicName"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"text"}
                  title={
                    "Buscar música en Youtube, Ejm: Lady Gaga - Poker Face"
                  }
                  required
                />
              )}
            />
          </div>
          <div className="col-span-12">
            {loadingVideo && (
              <div className="mt-4 mb-4 text-center">Cargando...</div>
            )}
            {videoUrl && (
              <div className="mt-2 mb-2">
                <iframe
                  width="100%"
                  height="315"
                  src={videoUrl}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  title="YouTube Video"
                ></iframe>
              </div>
            )}
          </div>
          <div className="col-span-12">
            <Controller
              name="linkMusic"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"link"}
                  title={"Enlace de la música"}
                  required
                />
              )}
            />
          </div>
          <div className="col-span-12">
            <Controller
              name="descriptionMusic"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <InputDefault
                  value={value}
                  onChange={onChange}
                  type={"textarea"}
                  title={"Comentarios"}
                  minRows={3}
                />
              )}
            />
          </div>
          <div className="col-span-12">
            <Button
              type="submit"
              color="success"
              variant="contained"
              className="w-full"
            >
              Enviar
            </Button>
          </div>
        </div>
      </form>
    </PublicTemplate>
  );
};

export default RenderDjSuggestionsClient;
