import ContainerInput from "../../../components/ContainerInput";
import DynamicTable from "../../planning/ui/dynamicTable";
import OrderInfoPurchase from "./order-info.purchase";

const SupplierRating = ({ dataSupplier, setDataSuppliers }) => {
  const options = [
    { value: "1", label: "Muy buena" },
    { value: "2", label: "Buena" },
    { value: "3", label: "Regular" },
    { value: "4", label: "Mala" },
    { value: "5", label: "Muy mala" },
  ];

  const columns = [
    {
      id: "rut",
      label: "RUT",
      minWidth: 80,
      align: "left",
    },
    {
      id: "name",
      label: "Nombre",
      minWidth: 80,
      align: "left",
    },
    {
      id: "id_question_1",
      label: "¿Cómo evaluaría la calidad del producto entregado?",
      minWidth: 80,
      align: "left",
      input: "select",
      activeInputTitle: false,
      options,
    },
    {
      id: "id_question_2",
      label: "¿El tiempo de entrega fue acorde a lo solicitado?",
      minWidth: 80,
      align: "left",
      input: "select",
      activeInputTitle: false,
      options,
    },
    {
      id: "id_question_3",
      label:
        "¿Cómo calificaría la comunicación y atención del proveedor durante el proceso de compra?",
      minWidth: 80,
      align: "left",
      input: "select",
      activeInputTitle: false,
      options,
    },
    {
      id: "id_question_4",
      label: "¿El precio del producto fue justo en relación a su calidad?",
      minWidth: 80,
      align: "left",
      input: "select",
      activeInputTitle: false,
      options,
    },
    {
      id: "id_question_5",
      label:
        "¿Recomendaría continuar trabajando con este proveedor basado en su desempeño general?",
      minWidth: 80,
      align: "left",
      input: "select",
      activeInputTitle: false,
      options,
    },
  ];

  return (
    <>
      <div className="p-2 mt-4">
        <h3 className="text-sm font-semibold">Calificación de proveedores</h3>
      </div>
      <ContainerInput className="p-0">
        <DynamicTable
          columns={columns}
          rows={dataSupplier}
          setRows={($rows) => setDataSuppliers($rows)}
          load={false}
          error={false}
          pagination={true}
        />
      </ContainerInput>
    </>
  );
};

const RatePurchase = ({
  dataPurchase,
  dataSuppliers,
  dataQuestionsSupplier,
  setDataSuppliers,
}) => {
  return (
    <>
      <OrderInfoPurchase
        selectedInfo={{
          codigo: dataPurchase?.code_purchase,
          priority: dataPurchase?.priority_purchase,
          purchaseDate: dataPurchase?.date_purchase,
          description: dataPurchase?.description_purchase,
        }}
        isComponentFinal={true}
      />

      <SupplierRating
        dataSupplier={dataSuppliers}
        dataQuestionsSupplier={dataQuestionsSupplier}
        setDataSuppliers={setDataSuppliers}
      />
    </>
  );
};

export default RatePurchase;
