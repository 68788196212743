import {
  Chip,
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useAppContext } from "./state.orderHistory";

import "moment/locale/es";

import { Visibility } from "@mui/icons-material";
import moment from "moment/moment";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import ContainerInput from "../../components/ContainerInput";
import { formatMoney } from "../../functions/formatMoney";
import InputDefault from "../../atomic/atoms/input.atoms";
import { update_caja } from "../../services/caja.services";

const CustomTable = ({ rows, isLoad, handleOpenModal, isRCSDH }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(30);
  const [newRows, setNewRows] = React.useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChange = (e, cajaId) => {
    const { name, value } = e.target;

    setNewRows((prev) =>
      prev.map((item) => {
        if (item.id_caja === cajaId) {
          return {
            ...item,
            [name]: Number(value),
          };
        }

        return item;
      })
    );

    update_caja({ [name]: Number(value) }, cajaId);
  };

  const TotApertura = newRows.reduce(
    (acc, row) => acc + Number(row.totapertura_caja || 0),
    0
  );

  const TotCierre = newRows.reduce(
    (acc, row) =>
      acc +
      Number(row.totcierre_caja || 0) +
      Number(row.totCover?.tot_cover) * 7000,
    0
  );

  const TotPersonas = newRows.reduce(
    (acc, row) => acc + Number(row.totPersonas || 0),
    0
  );

  const TotMesas = newRows.reduce(
    (acc, row) => acc + (Number(row.totMesas) || 0),
    0
  );

  const TotPerAssistedBruto = newRows.reduce(
    (acc, row) => acc + Number(row.totAssistedBruto || 0),
    0
  );

  const TotPerAssistedNeto = newRows.reduce(
    (acc, row) => acc + Number(row.totAssistedNeto || 0),
    0
  );

  const TotPerReleased = newRows.reduce(
    (acc, row) => acc + Number(row.totPAXReleased || 0),
    0
  );

  const TotAbonado = newRows.reduce(
    (acc, row) => acc + Number(row.totAbono || 0),
    0
  );

  React.useEffect(() => {
    setNewRows(rows);
  }, [rows]);

  if (isLoad) {
    return (
      <div style={{ textAlign: "center", padding: "20px" }}>
        <CircularProgress color="inherit" /> <br />
        <Typography component="h5" variant="h5" style={{ textAlign: "center" }}>
          Cargando...
        </Typography>
      </div>
    );
  }

  if (newRows.length === 0) {
    return <NotFound texto="No se encontró registros." />;
  }

  return (
    <>
      <TableContainer sx={{ maxHeight: 550 }}>
        <Table stickyHeader aria-label="sticky table" size={"small"}>
          <TableHead>
            <TableRow className="!font-bold">
              <TableCell align={"left"} style={{ minWidth: 80 }}>
                Código
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 100 }}>
                Estado
              </TableCell>
              {/* <TableCell align={"left"} style={{ minWidth: 110 }}>
                Cajero
              </TableCell> */}
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Fecha de apertura
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 190 }}>
                Fecha de cierre
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 150 }}>
                Tiempo operación
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Tot. apertura
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Tot. Abono
              </TableCell>
              <TableCell align={"left"} style={{ minWidth: 120 }}>
                Tot. cierre
              </TableCell>

              {isRCSDH && (
                <>
                  <TableCell align={"left"} style={{ minWidth: 130 }}>
                    # p. asist. bruto
                  </TableCell>
                  <TableCell align={"left"} style={{ minWidth: 130 }}>
                    # p. liberadas
                  </TableCell>
                  <TableCell align={"left"} style={{ minWidth: 130 }}>
                    # p. asist. neto
                  </TableCell>
                </>
              )}

              {!isRCSDH && (
                <>
                  <TableCell align={"left"} style={{ minWidth: 110 }}>
                    # personas
                  </TableCell>
                  <TableCell align={"left"} style={{ minWidth: 110 }}>
                    # mesas
                  </TableCell>
                  <TableCell align={"left"} style={{ minWidth: 120 }}>
                    Ticket prom. persona
                  </TableCell>
                  <TableCell align={"left"} style={{ minWidth: 120 }}>
                    Ticket prom. mesa
                  </TableCell>
                </>
              )}

              <TableCell align={"center"} style={{ minWidth: 80 }}>
                Observar
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {newRows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, idx) => (
                <TableRow hover role="checkbox" tabIndex={-1} key={idx}>
                  <TableCell align={"left"}>{row.id_caja}</TableCell>
                  <TableCell align={"left"}>
                    <Chip
                      color={
                        row.status_caja === "CERRADO" ? "success" : "error"
                      }
                      label={row.status_caja}
                      variant="outlined"
                    />
                  </TableCell>
                  {/* <TableCell align={"left"}>{row.usu_nombre}</TableCell> */}
                  <TableCell align={"left"}>
                    {row.created_apertura_caja &&
                      moment(row.created_apertura_caja).format(
                        "DD/MM/yyyy HH:mm:ss"
                      )}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.created_cierre_caja &&
                      moment(row.created_cierre_caja).format(
                        "DD/MM/yyyy HH:mm:ss"
                      )}
                  </TableCell>
                  <TableCell align={"left"}>
                    {row.created_cierre_caja &&
                      row.created_apertura_caja &&
                      moment(row.created_cierre_caja)
                        .diff(moment(row.created_apertura_caja), "hours", true)
                        .toFixed(2)}
                  </TableCell>
                  <TableCell align={"left"}>
                    {formatMoney(row.totapertura_caja || 0)}
                  </TableCell>
                  {/* <TableCell align={"left"}>
                    {formatMoney(row.totcierre_caja || 0)}
                  </TableCell> */}
                  <TableCell align={"left"}>{formatMoney(row?.totAbono || 0)}</TableCell>
                  <TableCell align={"left"}>
                    {formatMoney(
                      (row?.totcierre_caja || 0) +
                        (row?.totCover?.tot_cover || 0) * 7000
                    )}
                  </TableCell>

                  {isRCSDH && (
                    <>
                      <TableCell align={"left"}>
                        {row.totAssistedBruto}
                      </TableCell>
                      <TableCell align={"left"}>{row.totPAXReleased}</TableCell>
                      <TableCell align={"left"}>
                        {row.totAssistedNeto}
                      </TableCell>
                    </>
                  )}

                  {!isRCSDH && (
                    <>
                      <TableCell align={"left"}>
                        <InputDefault
                          value={row.totPersonas}
                          type="number"
                          title="Personas"
                          name="totPersonas"
                          onChange={(e) => handleChange(e, row.id_caja)}
                        />
                      </TableCell>
                      <TableCell align={"left"}>
                        <InputDefault
                          value={row.totMesas}
                          type="number"
                          title="Mesas"
                          name="totMesas"
                          onChange={(e) => handleChange(e, row.id_caja)}
                        />
                      </TableCell>
                      <TableCell align={"left"}>
                        {formatMoney(row.ticketPromPersona || 0)}
                      </TableCell>
                      <TableCell align={"left"}>
                        {formatMoney(row.ticketPromMesa || 0)}
                      </TableCell>
                    </>
                  )}

                  <TableCell align={"center"}>
                    <IconButton
                      color="secondary"
                      onClick={() => handleOpenModal(row)}
                    >
                      <Visibility />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
          <TableFooter
            style={{
              position: "sticky",
              bottom: 0,
              borderTop: "1px red solid",
              backgroundColor: "#fbfafd",
            }}
          >
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell align="left">
                {formatMoney(TotApertura || 0)}
              </TableCell>
              <TableCell align="left">
                {formatMoney(TotAbonado || 0)}
              </TableCell>
              <TableCell align="left">{formatMoney(TotCierre || 0)}</TableCell>
              {isRCSDH && (
                <>
                  <TableCell align="left">{TotPerAssistedBruto}</TableCell>
                  <TableCell align="left">{TotPerReleased}</TableCell>
                  <TableCell align="left">{TotPerAssistedNeto}</TableCell>
                </>
              )}

              {!isRCSDH && (
                <>
                  <TableCell align="left">{TotPersonas}</TableCell>
                  <TableCell align="left">{TotMesas}</TableCell>
                  <TableCell align="left">
                    {TotPersonas > 0
                      ? formatMoney(TotCierre / TotPersonas || 0)
                      : 0}
                  </TableCell>
                  <TableCell align="left">
                    {TotMesas > 0 ? formatMoney(TotCierre / TotMesas || 0) : 0}
                  </TableCell>
                </>
              )}

              <TableCell align="left"></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage="Filas por página"
        rowsPerPageOptions={[0, 30, 60, 90, 120, { value: -1, label: "Todo" }]}
        component="div"
        count={newRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};

const HistoryTable = ({ isRCSDH }) => {
  const state = useAppContext();

  return (
    <ContainerInput
      style={{
        marginTop: 20,
        marginBottom: 20,
        padding: "0px",
        textAlign: "center",
        borderRadius: "0px",
      }}
    >
      <CustomTable
        rows={state.data}
        isLoad={state.isLoadData}
        handleOpenModal={state.handleModal}
        isRCSDH={isRCSDH}
      />
    </ContainerInput>
  );
};

export default HistoryTable;
