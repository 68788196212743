import dayjs from "dayjs";
import ContainerSummary from "./container-summary.purchase";
import { formatMoney } from "../../../functions/formatMoney";

const OrderInfoPurchase = ({ selectedInfo, isComponentFinal = true }) => {
  return (
    <>
      <div className="p-2">
        <h3 className="text-sm font-semibold">Datos de la compra</h3>
      </div>
      <ContainerSummary withBorder={isComponentFinal} className="p-4">
        <div className="grid grid-cols-12 gap-2 w-full">
          {selectedInfo?.codigo && (
            <div className="col-span-12 md:col-span-12">
              <b className="text-purple-700">Nº de compra:</b>{" "}
              <span>{selectedInfo?.codigo}</span>
            </div>
          )}
          <div className="col-span-12 md:col-span-12">
            <b className="text-purple-700">Prioridad:</b>{" "}
            <span>{selectedInfo?.priority}</span>
          </div>
          <div className="col-span-12 md:col-span-12">
            <b className="text-purple-700">Fecha de creación:</b>{" "}
            <span>
              {selectedInfo?.purchaseDate &&
                dayjs(selectedInfo?.purchaseDate).format("DD/MM/YYYY HH:mm")}
            </span>
          </div>
          {selectedInfo?.datepaidreceipt && (
            <div className="col-span-12 md:col-span-12">
              <b className="text-purple-700">Fecha de compra:</b>{" "}
              <span>
                {selectedInfo?.datepaidreceipt &&
                  dayjs(selectedInfo?.datepaidreceipt).format(
                    "DD/MM/YYYY HH:mm"
                  )}
              </span>
            </div>
          )}
          {selectedInfo?.amountpaidreceipt && (
            <div className="col-span-12 md:col-span-12">
              <b className="text-purple-700">Pago total:</b>{" "}
              <span>
                {formatMoney(Number(selectedInfo?.amountpaidreceipt || 0))}
              </span>
            </div>
          )}
          <div className="col-span-12 md:col-span-12">
            <b className="text-purple-700">Observaciones de la compra:</b>{" "}
            <span>{selectedInfo?.description}</span>
          </div>

          {selectedInfo?.descriptionreceipt && (
            <div className="col-span-12 md:col-span-12">
              <b className="text-purple-700">Comentarios del recibo:</b>{" "}
              <span>{selectedInfo?.descriptionreceipt}</span>
            </div>
          )}
        </div>
      </ContainerSummary>
    </>
  );
};

export default OrderInfoPurchase;
