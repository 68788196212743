/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import * as React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";

import { Chip, Grid, Stack } from "@mui/material/";

import Input from "../../../components/Forms/Input";

import { Controller, useForm } from "react-hook-form";

import { Close } from "@mui/icons-material";

import toast from "react-hot-toast";

import useCategoriaProducto from "../../../hooks/useCategoriaProducto";

import InputDefault from "../../../atomic/atoms/input.atoms";
import useSubCategoriaProducto from "../../../hooks/useSubCategoriaProducto";
import { get_medidas } from "../../../services/menu.services";
import {
  add_producto,
  delete_producto,
  get_suppliersby_product,
  update_producto,
} from "../../../services/productos.services";
import { get_supplier } from "../../../services/supplier.services";

const defaultValues = {
  prod_nombre: "",
  marc_id: "",
  cat_id: "",
  id_subcategoria: "",
  prod_descripcion: "",
  prod_precio: 0,
  value_medida: 0,
};

const ModalProduct = ({
  open,
  setOpen,
  dataSelected,
  clearSelected,
  callBack,
}) => {
  const { control, reset, handleSubmit } = useForm({
    defaultValues,
  });

  const [loadPage, setLoadPage] = React.useState(false);

  const { dataCategoriaProducto } = useCategoriaProducto();
  const { dataSubCategoriaProducto } = useSubCategoriaProducto();

  const [dataMedidas, setDataMedida] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const [dataSupplier, setDataSupplier] = React.useState({
    data: [],
    load: false,
    err: false,
  });

  const [supplierSelected, setSupplierSelected] = React.useState([]);

  const handleDeleteSupplier = (value) => {
    const newData = supplierSelected.filter((el) => el.value !== value);
    setSupplierSelected(newData);
  };

  const handleSelectSupplier = (value) => {
    // find if the supplier is already selected
    const getSupplier = supplierSelected.some(
      (el) => Number(el.value) === Number(value)
    );

    // if the supplier is already selected, do nothing
    if (getSupplier) return;

    // if the supplier is not selected, add it to the selected sucursals
    const newSupplier = dataSupplier.data.find(
      (el) => Number(el.value) === Number(value)
    );

    setSupplierSelected([...supplierSelected, newSupplier]);
  };

  const handleGetSuppliers = async (id) => {
    try {
      setLoadPage(true);
      let response = await get_suppliersby_product(id);
      setLoadPage(false);

      response = response.map((el) => ({
        value: el.id_supplier,
        label: el?.name_supplier,
      }));

      setSupplierSelected(response);
    } catch (err) {
      console.log(err);
    }
  };

  const handleAddEdit = async (data) => {
    try {
      setLoadPage(true);
      if (data.prod_id) {
        await update_producto({
          supplier: supplierSelected,
          ...data,
        });

        toast.success("Producto modificado correctamente");
      } else {
        await add_producto({
          supplier: supplierSelected,
          ...data,
        });

        toast.success("Producto creada correctamente");
      }

      await callBack();

      setLoadPage(false);
      setOpen(false);
    } catch (err) {
      toast.error("Hubo un error al crear el producto");
      setLoadPage(false);
    }
  };

  const handleDelete = async (data) => {
    try {
      setLoadPage(true);
      await delete_producto(data.prod_id);
      setLoadPage(false);

      toast.success("El producto fue eliminado correctamente");

      await callBack();

      setLoadPage(false);
      setOpen(false);
    } catch (err) {
      toast.error("Hubo un error al eliminar el producto");
      setLoadPage(false);
    }
  };

  React.useEffect(() => {
    async function handleSupplier() {
      try {
        setDataSupplier({ load: true, data: [], err: true });

        const response = await get_supplier();

        setDataSupplier({
          load: false,
          data: response.map((el) => ({
            label: el.name_supplier,
            value: el.id_supplier,
          })),
          err: false,
        });
      } catch (err) {
        setDataSupplier({ load: false, data: [], err: true });
        console.log(err);
      }
    }

    handleSupplier();
  }, []);

  React.useEffect(() => {
    async function obtainMedidas() {
      try {
        setDataMedida({ load: true, data: [], err: false });
        let response = await get_medidas();

        setDataMedida({
          load: false,
          data: response.map((el) => ({
            label: el.name_medida,
            value: el.id_medida,
          })),
          err: false,
        });
      } catch (err) {
        setDataMedida({ load: false, data: [], err: true });
        console.log(err);
      }
    }

    obtainMedidas();
  }, []);

  React.useEffect(() => {
    if (open && dataSelected?.prod_id > 0) {
      reset(dataSelected);
      handleGetSuppliers(dataSelected.prod_id);
    } else {
      reset(defaultValues);
      clearSelected();
      setSupplierSelected([]);
    }
  }, [open]);

  if (dataSelected?.delete) {
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleDelete)} autoComplete="off">
          <DialogTitle>
            ¡Importante!
          </DialogTitle>
          <DialogContent>
            ¿Estas seguro de eliminar el producto{" "}
            <b className="uppercase text-red-600">
              {dataSelected?.prod_nombre}
            </b>
            ?
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="error"
              variant="contained"
              disabled={loadPage}
            >
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
      >
        <form onSubmit={handleSubmit(handleAddEdit)} autoComplete="off">
          <DialogTitle>
            {dataSelected?.prod_id ? "Editar producto" : "Agregar producto"}
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Grid container spacing={1} className="!mt-2">
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="prod_nombre"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="text"
                      title="Nombre"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="cat_id"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      select
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataCategoriaProducto.data}
                      title="Categoría"
                    />
                  )}
                />
              </Grid>
              {/* <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="id_subcategoria"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      select
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataSubCategoriaProducto.data}
                      title="Sub Categoría"
                    />
                  )}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} lg={12}>
                <div className="border-2 border-dashed border-slate-200 rounded-lg p-2 mt-2">
                  <InputDefault
                    onChange={(el) => handleSelectSupplier(el.target.value)}
                    type="select"
                    disabled={dataSupplier.load}
                    title={"Proveedor"}
                    options={dataSupplier.data}
                  />

                  {supplierSelected.length === 0 && (
                    <div className="text-center mt-3">
                      <h3 className="text-sm text-slate-500">
                        No hay proveedores seleccionados
                      </h3>
                    </div>
                  )}

                  <Stack direction="row" spacing={1} className="mt-2">
                    {supplierSelected.map((el) => (
                      <Chip
                        key={el.value}
                        label={el.label}
                        variant="outlined"
                        color="secondary"
                        onDelete={() => handleDeleteSupplier(el.value)}
                      />
                    ))}
                  </Stack>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="id_medida"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      fullWidth
                      select
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="select"
                      options={dataMedidas.data}
                      title="Unidad de medida"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="value_medida"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      title="Valor de la medida"
                      step="any"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <Controller
                  name="prod_precio"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      required
                      value={value || ""}
                      onChange={onChange}
                      type="number"
                      title="Precio (CLP)"
                      step="any"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} lg={12}>
                <Controller
                  name="prod_descripcion"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Input
                      value={value || ""}
                      onChange={onChange}
                      type="textarea"
                      title="Descripción"
                      minRows={3}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              variant="text"
              disabled={loadPage}
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="secondary"
              variant="contained"
              disabled={loadPage}
            >
              {dataSelected?.prod_id ? "Editar" : "Agregar"}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

export default ModalProduct;
