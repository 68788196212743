/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import Template from "../../components/Template";

import { get_producto } from "../../services/productos.services";

import ContainerInput from "../../components/ContainerInput";
// import LoadingPage from "../../components/LoadingPage";

import CustomTable from "../../atomic/organisms/Table.organisms";
import { formatMoney } from "../../functions/formatMoney";
import FilterProducts from "./ui/filters.products";
import ModalProduct from "./ui/modal-products";
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from "@mui/material";
import { Category } from "@mui/icons-material";
import DialogCategory from "../menu/_ui/DialogCategory.menu";

const Productos = () => {
  const [dataProducto, setDataProducto] = useState({
    load: false,
    err: false,
    data: [],
  });

  const [openSpeedDial, setOpenSpeedDial] = useState(false);
  const [openDialog, setOpenDialog] = useState({
    openBrand: false,
    openCategory: false,
    openFoodInputs: false,
  });

  const [dataSelected, setDataSelected] = useState(null);

  const [openCrud, setOpenCrud] = useState(false);

  const handleOpenDialog = (name, value) => {
    setOpenDialog({
      ...openDialog,
      [name]: value,
    });
  };

  const handleSearchProduct = async (value) => {
    setDataProducto({ load: true, err: false, data: [] });
    const response = await get_producto();

    let filterData = response;

    if (value?.searchName) {
      filterData = filterData.filter((el) => {
        return (
          el.prod_nombre
            .toLowerCase()
            .includes(value.searchName.toLowerCase()) ||
          el.prod_descripcion
            .toLowerCase()
            .includes(value.searchName.toLowerCase())
        );
      });
    }

    if (value?.idCategory) {
      filterData = filterData.filter(
        (el) => el.cat_id === Number(value.idCategory)
      );
    }

    if (value?.idSubcategory) {
      filterData = filterData.filter(
        (el) => el.id_subcategoria === Number(value.idSubcategory)
      );
    }

    setDataProducto({ load: false, err: false, data: filterData });
  };

  async function fetchData() {
    try {
      setDataProducto({ ...dataProducto, load: true, data: [], err: false });

      const response = await get_producto();

      setDataProducto({
        ...dataProducto,
        load: false,
        data: response,
        err: false,
      });
    } catch (err) {
      setDataProducto({ ...dataProducto, load: false, data: [], err: true });
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Template title="Productos">
      <FilterProducts
        onLoadData={dataProducto.load}
        onSearchData={handleSearchProduct}
        onOpenAddModal={setOpenCrud}
      />

      <ContainerInput className="!p-0">
        <CustomTable
          columns={[
            {
              id: "prod_cod",
              label: "Código",
              minWidth: 30,
              align: "left",
            },
            {
              id: "cat_nombre",
              label: "Categoría",
              minWidth: 80,
              align: "left",
            },
            // {
            //   id: "name_subcategoria",
            //   label: "Sub Categoría",
            //   minWidth: 80,
            //   align: "left",
            // },
            {
              id: "prod_nombre",
              label: "Nombre",
              minWidth: 30,
              align: "left",
            },
            {
              id: "prod_precio",
              label: "Precio",
              minWidth: 60,
              align: "left",
              format: (item) => {
                return formatMoney(item);
              },
            },
            {
              id: "name_medida",
              label: "Unidad medida",
              minWidth: 80,
              align: "left",
            },
            {
              id: "value_medida",
              label: "Valor medida",
              minWidth: 80,
              align: "left",
            },
          ]}
          rows={dataProducto.data}
          load={dataProducto.load}
          error={false}
          pagination={true}
          actionEdit={(element) => {
            setOpenCrud(true);
            setDataSelected(element);
          }}
          actionDelete={(element) => {
            setOpenCrud(true);
            setDataSelected({ ...element, delete: true });
          }}
        />
      </ContainerInput>

      <ModalProduct
        dataSelected={dataSelected}
        clearSelected={() => setDataSelected(null)}
        open={openCrud}
        setOpen={setOpenCrud}
        callBack={() => {
          fetchData();
        }}
      />

      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        icon={<SpeedDialIcon />}
        onClose={() => setOpenSpeedDial(false)}
        onOpen={() => setOpenSpeedDial(true)}
        open={openSpeedDial}
        color="secondary"
        className="fixed bottom-0 right-0 mr-4 mb-4"
      >
        {/* <SpeedDialAction
          key={"Marca"}
          icon={<Texture />}
          tooltipTitle={"Marca"}
          tooltipOpen
          onClick={() => {
            handleOpenDialog("openBrand", true);
          }}
        /> */}
        <SpeedDialAction
          key={"Categoría"}
          icon={<Category />}
          tooltipTitle={"Categoría"}
          tooltipOpen
          onClick={() => {
            handleOpenDialog("openCategory", true);
          }}
        />
      </SpeedDial>

      {openDialog?.openCategory && (
        <DialogCategory
          open={openDialog.openCategory}
          setOpen={() => handleOpenDialog("openCategory", false)}
          callback={() => fetchData()}
        />
      )}
    </Template>
  );
};

export default Productos;
