import dayjs from "dayjs";
import React, { useEffect } from "react";
import CustomTable from "../../atomic/organisms/Table.organisms";
import ContainerInput from "../../components/ContainerInput";
import Template from "../../components/Template";

import { Alert } from "@mui/material";
import { getSuggestDj } from "../../services/suggest-dj.services";
import { getUser } from "../../services/auth.services";
import { Download } from "@mui/icons-material";

const DjSuggestions = (props) => {
  const { socket } = props;

  const [dataSuggestions, setDataSuggetions] = React.useState([]);
  const [load, setLoad] = React.useState(false);

  const userData = getUser();

  const filterTime = (data) => {
    const oneHourAgoUTC = dayjs.utc().subtract(4, "hour");

    return data.filter((el) => {
      const recordTimeUTC = dayjs.utc(el.createdat_suggestdj);

      return recordTimeUTC.isAfter(oneHourAgoUTC);
    });
  };

  const fetchDataSuggestions = async () => {
    try {
      setLoad(true);

      let response = await getSuggestDj();

      response = filterTime(response);

      setDataSuggetions(response);

      setLoad(false);
    } catch (error) {
      console.error("Error en fetchDataSuggestions: ", error);
    }
  };

  useEffect(() => {
    socket.on("suggest-dj-created", (element) => {
      const { data } = element;

      if (userData.empresa.id_emp !== data.id_emp) {
        return;
      }

      setDataSuggetions((prev) => {
        return [...prev, data];
      });
    });

    return () => {
      socket.off("suggest-dj-created", (element) => {});
    };
  }, []);

  useEffect(() => {
    fetchDataSuggestions();
  }, []);

  return (
    <Template title="Reporte" style={{ position: "relative" }}>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-4">
          <ContainerInput style={{ padding: 10 }}>
            Número músicas pedidas: {dataSuggestions.length}
          </ContainerInput>
        </div>
        <Alert severity="info" className="col-span-12 md:col-span-8" b>
          <p>
            En esta sección podrás ver todas las recomendaciones de los
            clientes, con 1 hora de antigüedad.
          </p>
        </Alert>
        <div className="col-span-12 md:col-span-12">
          <ContainerInput className="p-0">
            <CustomTable
              columns={[
                {
                  id: "video_id",
                  label: "Descargar",
                  minWidth: 80,
                  align: "center",
                  format: (el) => {
                    if (!el) {
                      return null;
                    }

                    return (
                      <a
                        href={`https://www.y2mate.com/youtube/${el}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        <Download />
                      </a>
                    );
                  },
                },
                // {
                //   id: "title_suggestdj",
                //   label: "Titulo",
                //   minWidth: 100,
                //   align: "left",
                //   format: (el) => {
                //     return <span className="uppercase">{el}</span>;
                //   },
                // },
                {
                  id: "music_description",
                  label: "Descripción de la música",
                  minWidth: 100,
                  align: "left",
                  format: (el) => {
                    return <span className="uppercase">{el}</span>;
                  },
                },
                {
                  id: "link_suggestdj",
                  label: "Link de la música",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return (
                      <a
                        href={el}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        {el}
                      </a>
                    );
                  },
                },
                {
                  id: "text_suggestdj",
                  label: "Comentarios",
                  minWidth: 300,
                  align: "left",
                  format: (el) => {
                    return <span className="normal-case">{el}</span>;
                  },
                },
                {
                  id: "createdat_suggestdj",
                  label: "Fecha",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY HH:mm");
                  },
                },
              ]}
              rows={dataSuggestions}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
        </div>
      </div>
    </Template>
  );
};

export default DjSuggestions;
