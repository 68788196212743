import { Tab, Tabs } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import InputAutoComplete from "../../../atomic/molecule/autocomplete.molecule";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerInput from "../../../components/ContainerInput";
import { getPlanning } from "../../../services/planning.services";
import { get_plato } from "../../../services/plato.services";
import DynamicTable from "../../planning/ui/dynamicTable";

const ChooseEventsPurchase = ({
  seletedEvent,
  onSelectEvent,
  selectedPlatos,
  onSelectPlatos,
  setTypePurchase,
}) => {
  const [dataPlanning, setDataPlanning] = useState([]);

  const [platosMemory, setPlatosMemory] = useState([]);

  const [load, setLoad] = useState(false);

  const [tabEventos, setTabEventos] = useState(0);

  const fetchDataPlanning = async () => {
    try {
      setLoad(true);
      let data = await getPlanning();

      data = data.map((el) => {
        const selected = seletedEvent.some(
          (event) => event.id_planning === el.id_planning
        );

        return {
          ...el,
          selected: selected,
          canBeSelectable: true,
        };
      });

      setLoad(false);

      setDataPlanning(data);
    } catch (error) {
      console.error("Error en fetchDataPlanning: ", error);
    }
  };

  const onRowSelected = (row) => {
    setDataPlanning((prev) => {
      const newSelected = prev.map((el) => {
        if (el.id_planning === row.id_planning) {
          return {
            ...el,
            selected: !el.selected,
          };
        }

        return el;
      });

      onSelectEvent(
        newSelected.map((el) => ({
          id_planning: el.id_planning,
          selected: el.selected,
          cod_planning: el.cod_planning,
          name_event: el.name_event,
          name_typeevent: el.name_typeevent,
          usu_nombre: el.usu_nombre,
          created_at_planning: el.created_at_planning,
        }))
      );

      return newSelected;
    });
  };

  async function fetchProducts() {
    let response = await get_plato();

    response = response.filter((el) => el?.productos.length > 0);

    setPlatosMemory(response);
  }

  useEffect(() => {
    fetchDataPlanning();
    fetchProducts();
  }, []);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12 md:col-span-12">
        {/* <FilterPlanning onSearchData={handleSearchData} onLoadData={load} /> */}
        <Tabs
          value={tabEventos}
          onChange={(event, newValue) => {
            setTabEventos(newValue);

            setTypePurchase(newValue);

            // reset the selected events
            onSelectEvent([]);
            setDataPlanning(
              dataPlanning.map((el) => ({ ...el, selected: false }))
            );
          }}
          className="!mb-4"
        >
          <Tab label="Gala" />
          <Tab label="RCS" />
        </Tabs>

        {tabEventos === 0 && (
          <ContainerInput className="p-0">
            <CustomTable
              onRowSelected={onRowSelected}
              columns={[
                {
                  id: "cod_planning",
                  label: "Código",
                  minWidth: 80,
                  align: "left",
                },
                {
                  id: "name_event",
                  label: "Evento",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "name_typeevent",
                  label: "Tipo de evento",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "usu_nombre",
                  label: "Usuario",
                  minWidth: 150,
                  align: "left",
                  format: (el) => {
                    return el.toUpperCase();
                  },
                },
                {
                  id: "created_at_planning",
                  label: "Fecha creación",
                  minWidth: 160,
                  align: "left",
                  format: (el) => {
                    return dayjs(el).utc().format("DD/MM/YYYY");
                  },
                },
              ]}
              rows={dataPlanning}
              load={load}
              error={false}
              pagination={true}
            />
          </ContainerInput>
        )}
        {tabEventos === 1 && (
          <>
            <div className="p-0 mb-4">
              <InputAutoComplete
                option={platosMemory.map((el) => ({
                  value: el.id_plato,
                  label: `#${el.id_plato} - ${el.nombre_plato}`,
                }))}
                label="Platos"
                name="plato"
                value={null}
                handleChange={(e) => {
                  let newDataPlatos = selectedPlatos || [];

                  const platoId = e?.target?.value;
                  const findPlato = platosMemory.find(
                    (el) => Number(el.id_plato) === Number(platoId)
                  );

                  if (findPlato) {
                    let existPlato = selectedPlatos.some(
                      (el) => el.id_plato === findPlato.id_plato
                    );

                    if (!existPlato) {
                      onSelectPlatos([
                        ...newDataPlatos,
                        { ...findPlato, quantity: 1 },
                      ]);
                    }
                  }
                }}
                className="w-80"
              />
            </div>
            <ContainerInput className="p-0">
              <DynamicTable
                columns={[
                  {
                    id: "id_plato",
                    label: "Código",
                    minWidth: 80,
                    align: "left",
                    format: (el) => {
                      return `#${el}`;
                    },
                  },
                  {
                    id: "nombre_plato",
                    label: "Nombre del plato",
                    minWidth: 100,
                    align: "left",
                    format: (el) => {
                      return el.toUpperCase();
                    },
                  },
                  {
                    id: "quantity",
                    label: "Cantidad",
                    minWidth: 100,
                    align: "left",
                    input: "number",
                  },
                  {
                    id: "descripcion_plato",
                    label: "Descripción del plato",
                    minWidth: 150,
                    align: "left",
                    format: (el) => {
                      return el.toUpperCase();
                    },
                  },
                  {
                    id: "delete",
                    label: "Eliminar",
                    input: "delete",
                    minWidth: 10,
                    align: "left",
                  },
                ]}
                rows={selectedPlatos}
                load={false}
                pagination={true}
                isReadMode={false}
                setRows={($rows) => onSelectPlatos($rows)}
              />
            </ContainerInput>
          </>
        )}
      </div>
    </div>
  );
};

export default ChooseEventsPurchase;
