import ContainerInput from "../../../components/ContainerInput";
import InputDefault from "../../../components/Forms/Input";
import { formatMoney } from "../../../functions/formatMoney";
import DynamicTable from "../../planning/ui/dynamicTable";
import OrderInfoPurchase from "./order-info.purchase";

const calculateTotal = (data) => {
  return data.reduce((acc, el) => acc + Number(el.amountPaid), 0);
};

const UploadReceipts = ({ dataPurchase, setDataPurchase }) => {
  const columns = [
    {
      id: "receiptFile",
      label: "Subir comprobante",
      minWidth: 90,
      align: "left",
      input: "file",
    },
    {
      id: "amountPaid",
      label: "Monto pagado",
      minWidth: 100,
      align: "left",
      input: "number",
      activeInputTitle: false,
    },
    {
      id: "delete",
      label: "Eliminar",
      input: "delete",
      minWidth: 10,
      align: "left",
    },
  ];

  return (
    <>
      <OrderInfoPurchase
        selectedInfo={{
          codigo: dataPurchase?.code_purchase,
          priority: dataPurchase?.priority_purchase,
          purchaseDate: dataPurchase?.date_purchase,
          description: dataPurchase?.description_purchase,
        }}
        isComponentFinal={true}
      />
      <div className="p-2 mt-4">
        <h3 className="text-sm font-semibold">Comprobantes</h3>
      </div>
      <ContainerInput className="p-4">
        <div className="grid grid-cols-12 gap-4 w-full">
          <div className="col-span-12 md:col-span-12">
            <ContainerInput className="p-0">
              <DynamicTable
                showNumberOfRows={true}
                columns={columns}
                rows={dataPurchase?.receipts || []}
                load={false}
                pagination={true}
                isReadMode={false}
                addRow={true}
                setRows={($rows) =>
                  setDataPurchase({
                    ...dataPurchase,
                    receipts: $rows,
                    amountpaid_receipt: calculateTotal($rows),
                  })
                }
              />
            </ContainerInput>
          </div>
          <div className="col-span-12 md:col-span-6">
            <span className="text-sm font-semibold">Total pagado:</span>{" "}
            <InputDefault
              required
              loading={false}
              fullWidth
              value={formatMoney(dataPurchase?.amountpaid_receipt || 0)}
              name="amountpaid_receipt"
              disabled
              type={"text"}
            />
          </div>
          <div className="col-span-12 md:col-span-12">
            <InputDefault
              required
              loading={false}
              fullWidth
              value={dataPurchase?.description}
              onChange={(el) =>
                setDataPurchase({
                  ...dataPurchase,
                  description_receipt: el.target.value,
                })
              }
              type={"textarea"}
              title={"Comentarios / observaciones"}
              name="description_receipt"
              rows="4"
              cols="50"
            />
          </div>
        </div>
      </ContainerInput>
    </>
  );
};

export default UploadReceipts;
