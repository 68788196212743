import dayjs from "dayjs";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import ContainerSummary from "./container-summary.purchase";
import OrderInfoPurchase from "./order-info.purchase";

const EventsSummary = ({ selectedEvent, isComponentFinal = true }) => {
  const columnsEvents = [
    {
      id: "cod_planning",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "name_event",
      label: "Evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "name_typeevent",
      label: "Tipo de evento",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "usu_nombre",
      label: "Usuario",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "created_at_planning",
      label: "Fecha creación",
      minWidth: 160,
      align: "left",
      format: (el) => {
        return dayjs(el).utc().format("DD/MM/YYYY");
      },
    },
  ];

  return (
    <>
      <div className="p-2 mt-4">
        <h3 className="text-sm font-semibold">Eventos seleccionados</h3>
      </div>
      <ContainerSummary withBorder={isComponentFinal} className="!p-0">
        <CustomTable
          columns={columnsEvents}
          rows={selectedEvent}
          load={false}
          error={false}
          pagination={isComponentFinal}
        />
      </ContainerSummary>
    </>
  );
};

const BuyProducts = ({ selectedProducts, isComponentFinal = true }) => {
  const columns = [
    {
      id: "prod_id",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "prod_nombre",
      label: "Producto",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "suppliers_name",
      label: "Proveedores",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name_medida",
      label: "Medida",
      minWidth: 60,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "prod_stock",
      label: "Stock",
      minWidth: 60,
      align: "left",
    },
    {
      id: "value_product_by_event_total",
      label: "Cantidad",
      minWidth: 60,
      align: "left",
    },
    {
      id: "tota_buy",
      label: "Total a comprar",
      minWidth: 60,
      align: "left",
    },
  ];

  return (
    <>
      <div className="p-2 mt-4">
        <h3 className="text-sm font-semibold">Productos a comprar</h3>
      </div>
      <ContainerSummary withBorder={isComponentFinal} className="p-0">
        <CustomTable
          columns={columns}
          rows={selectedProducts}
          load={false}
          error={false}
          pagination={isComponentFinal}
        />
      </ContainerSummary>
    </>
  );
};

const SummaryPurchase = ({
  selectedEvent,
  selectedInfo,
  selectedProducts,
  isFinalComponent = false,
}) => {
  return (
    <>
      <OrderInfoPurchase
        selectedInfo={selectedInfo}
        isComponentFinal={!isFinalComponent}
      />
      <EventsSummary
        selectedEvent={selectedEvent}
        isComponentFinal={!isFinalComponent}
      />
      <BuyProducts
        selectedProducts={selectedProducts}
        isComponentFinal={!isFinalComponent}
      />
    </>
  );
};

export default SummaryPurchase;
