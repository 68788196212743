import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import OrderInfoPurchase from "./order-info.purchase";
import ContainerInput from "../../../components/ContainerInput";
import CustomTable from "../../../atomic/organisms/Table.organisms";
import dayjs from "dayjs";
import { formatMoney } from "../../../functions/formatMoney";
import { config } from "../../../config";
import { FileCopy } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ModalSeePurchase = (props) => {
  const { dataSelected, clearSelected, callBack, open, setOpen } = props;

  const purchaseData = dataSelected?.purchase;
  const productsData = dataSelected?.products;
  const receiptData = dataSelected?.receipts || [];
  const eventsData = dataSelected?.events || [];

  const productColumns = [
    {
      id: "prod_id",
      label: "Código",
      minWidth: 80,
      align: "left",
    },
    {
      id: "prod_nombre",
      label: "Producto",
      minWidth: 150,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "suppliers_name",
      label: "Proveedores",
      minWidth: 100,
      align: "left",
    },
    {
      id: "name_medida",
      label: "Medida",
      minWidth: 60,
      align: "left",
      format: (el) => {
        return el.toUpperCase();
      },
    },
    {
      id: "qtypaied_pupro",
      label: "Total a comprar",
      minWidth: 60,
      align: "left",
      input: "number",
    },
    {
      id: "pricepaied_pupro",
      label: "Total a pagar",
      minWidth: 80,
      align: "left",
      input: "text",
    },
  ];

  const eventColumns = [
    {
      id: "cod_planning",
      label: "Código del evento",
      minWidth: 80,
      align: "left",
    },
    {
      id: "created_at",
      label: "Fecha de creación",
      minWidth: 100,
      align: "left",
      format: (el) => {
        return dayjs(el).format("DD/MM/YYYY HH:mm");
      },
    },
  ];

  const receiptColumns = [
    {
      id: "namefile_pre",
      label: "Comprobante",
      minWidth: 100,
      align: "left",
      format: (item) => {
        if (!item)
          return (
            <div className={`text-gray-400 cursor-auto`}>
              <FileCopy />
            </div>
          );

        const url = `${config.doUrlSpace}/purchase/receipts/${item}`;

        return (
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className={`text-blue-500`}
          >
            <FileCopy /> {item}
          </a>
        );
      },
    },
    {
      id: "amountpaid_pre",
      label: "Monto pagado",
      minWidth: 100,
      align: "left",
      format: (el) => {
        if (el < 0) {
          return el;
        }

        return formatMoney(Number(el));
      },
    },
    {
      id: "created_at",
      label: "Fecha",
      minWidth: 100,
      align: "left",
      format: (el) => {
        return dayjs(el).format("DD/MM/YYYY HH:mm");
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);

    callBack();
  };

  React.useEffect(() => {
    if (open) {
    } else {
      clearSelected();
    }
  }, [open]);

  return (
    open && (
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar className="!sticky !top-0 !z-10" color="secondary">
          <Toolbar variant="regular">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Orden de compra #{dataSelected?.purchase?.code_purchase}
            </Typography>
          </Toolbar>
        </AppBar>
        <div className="p-6">  
          <OrderInfoPurchase
            selectedInfo={{
              codigo: purchaseData?.code_purchase,
              priority: purchaseData?.priority_purchase,
              purchaseDate: purchaseData?.date_purchase,
              description: purchaseData?.description_purchase,
              amountpaidreceipt: purchaseData?.amountpaidreceipt_purchase,
              datepaidreceipt: purchaseData?.datepaid_purchase,
              descriptionreceipt: purchaseData?.descriptionreceipt_purchase,
            }}
            isComponentFinal={true}
          />

          <div className="p-2">
            <h3 className="text-sm font-semibold">Eventos seleccionados</h3>
          </div>
          <ContainerInput className="p-0">
            <CustomTable
              columns={eventColumns}
              rows={eventsData}
              load={false}
              error={false}
              pagination={false}
            />
          </ContainerInput>

          <div className="p-2">
            <h3 className="text-sm font-semibold">Comprobantes cargados</h3>
          </div>
          <ContainerInput className="p-0">
            <CustomTable
              columns={receiptColumns}
              rows={receiptData}
              load={false}
              error={false}
              pagination={false}
            />
          </ContainerInput>

          <div className="p-2">
            <h3 className="text-sm font-semibold">Productos comprados</h3>
          </div>

          <ContainerInput className="p-0">
            <CustomTable
              columns={productColumns}
              rows={productsData}
              load={false}
              error={false}
              pagination={false}
            />
          </ContainerInput>
        </div>
      </Dialog>
    )
  );
};

export default ModalSeePurchase;
