import React, { useEffect } from "react";

import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@mui/material";
import InputDefault from "../../../atomic/atoms/input.atoms";

import { Controller, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import {
  add_supplier,
  delete_supplier,
  update_supplier,
} from "../../../services/supplier.services";

const supplierCategories = [
  { value: "Abarrotes", label: "Abarrotes" },
  { value: "Aceitunas y pepinillos", label: "Aceitunas y pepinillos" },
  { value: "Aseo", label: "Aseo" },
  { value: "Bebidas", label: "Bebidas" },
  { value: "Cárnicos", label: "Cárnicos" },
  { value: "Champiñones", label: "Champiñones" },
  { value: "Condimentos", label: "Condimentos" },
  { value: "Congelados", label: "Congelados" },
  { value: "Control de plagas", label: "Control de plagas" },
  { value: "Embutidos", label: "Embutidos" },
  { value: "Ferreteria", label: "Ferreteria" },
  { value: "Fondos de alcachofa", label: "Fondos de alcachofa" },
  { value: "Gas a Granel", label: "Gas a Granel" },
  { value: "Huevos", label: "Huevos" },
  { value: "Huevos codorniz", label: "Huevos codorniz" },
  { value: "Imprenta", label: "Imprenta" },
  { value: "Internet", label: "Internet" },
  { value: "Lacteos", label: "Lacteos" },
  { value: "Licores y Gaseosas", label: "Licores y Gaseosas" },
  { value: "Liquidos y Gaseosas", label: "Liquidos y Gaseosas" },
  { value: "Maquinaria", label: "Maquinaria" },
  { value: "Menaje", label: "Menaje" },
  { value: "Pan", label: "Pan" },
  { value: "Pasteleria", label: "Pasteleria" },
  { value: "Plasticos", label: "Plasticos" },
  { value: "Tortas", label: "Tortas" },
  { value: "Verduras", label: "Verduras" },
  { value: "Violinista", label: "Violinista" },
];

const initalValues = {
  idSupplier: "",
  nameSupplier: "",
  rutSupplier: "",
  contactSupplier: "",
  mailSupplier: "",
  telfSupplier: "",
  addressSupplier: "",
  observationsSupplier: "",
  categorySupplier: "",
};

const ModalCrudSupplier = (props) => {
  const { open, setOpen, dataSelected, clearSelected, callBack } = props;

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: initalValues,
  });

  const [load, setLoad] = React.useState(false);

  const onAction = async (data) => {
    try {
      setLoad(true);

      if (data.idSupplier) {
        await update_supplier(data, data.idSupplier);

        await callBack();

        toast.success("Se modifico el proveedor correctamente", {
          position: "top-center",
        });

        setLoad(false);
        setOpen(false);

        return;
      }

      await add_supplier(data);

      await callBack();

      toast.success("Se creo el proveedor correctamente", {
        position: "top-center",
      });

      setLoad(false);
      setOpen(false);
    } catch (err) {
      console.log(err);
      setLoad(false);
    }
  };

  const onActionDelete = async (data) => {
    try {
      setLoad(true);

      await delete_supplier(data.idSupplier);

      await callBack();

      toast.success("Se elimino el proveedor correctamente", {
        position: "top-center",
      });

      setLoad(false);
      setOpen(false);
    } catch (err) {
      console.log(err);
      setLoad(false);
    }
  };

  useEffect(() => {
    if (open) {
      if (dataSelected?.id_supplier && dataSelected?.id_supplier > 0) {
        reset({
          ...initalValues,
          idSupplier: Number(dataSelected.id_supplier),
          addressSupplier: dataSelected.address_supplier,
          contactSupplier: dataSelected.contact_supplier,
          mailSupplier: dataSelected.mail_supplier,
          nameSupplier: dataSelected.name_supplier,
          observationsSupplier: dataSelected.observations_supplier,
          rutSupplier: dataSelected.rut_supplier,
          telfSupplier: dataSelected.telf_supplier,
          categorySupplier: dataSelected.category_supplier,
        });
      }
    } else {
      clearSelected();
      reset(initalValues);
    }
  }, [open]);

  if (dataSelected?.delete) {
    return (
      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth={"sm"}
        aria-describedby="dialog-benefit"
      >
        <form onSubmit={handleSubmit(onActionDelete)} autoComplete="off">
          <DialogTitle>
            ¡Importante!
            <IconButton
              aria-label="close"
              onClick={() => setOpen(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </IconButton>
          </DialogTitle>
          <DialogContent className={`${load && "animate-pulse"} `}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                ¿Estás seguro que deseas eliminar el proveedor{" "}
                <b className="uppercase text-red-600">
                  {dataSelected?.name_supplier}
                </b>
                ?
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={load}
              color="inherit"
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Salir
            </Button>
            <Button
              type="submit"
              color="error"
              variant="contained"
              disabled={load}
            >
              Eliminar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth={"sm"}
      aria-describedby="dialog-benefit"
    >
      <form onSubmit={handleSubmit(onAction)} autoComplete="off">
        <DialogTitle>
          {watch("idSupplier") ? "Editar" : "Agregar"} proveedor
          <IconButton
            aria-label="close"
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent className={`${load && "animate-pulse"} `}>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="nameSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Nombre"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="rutSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"RUT"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="categorySupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Categoría"}
                    options={supplierCategories}
                    type={"select"}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <Controller
                name="contactSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    required
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Contacto"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="mailSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Email"}
                    type={"email"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="telfSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Teléfono"}
                    type={"tel"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="addressSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Dirección"}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                name="observationsSupplier"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <InputDefault
                    loading={false}
                    fullWidth
                    value={value}
                    onChange={onChange}
                    title={"Observaciones"}
                    type={"textarea"}
                    minRows={3}
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={load}
            color="inherit"
            variant="text"
            onClick={() => {
              setOpen(false);
            }}
          >
            Salir
          </Button>
          <Button
            type="submit"
            color="secondary"
            variant="contained"
            disabled={load}
          >
            {dataSelected?.id_supplier ? "Modificar" : "Agregar"}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
export default ModalCrudSupplier;
