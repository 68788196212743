import React, { useEffect, useState } from "react";

import Head from "../Head";

import BodyCarta from "./Components/BodyCarta";
import FooterCarta from "./Components/FooterCarta";
import HeadCarta from "./Components/HeadCarta";

import DialogSaveUserData from "../../pages/carta/SaveUserData/saveUserData";

import useEmpresa from "../../hooks/useEmpresa";

import { Toaster } from "react-hot-toast";
import NotFound from "../../atomic/organisms/NotFound.organisms";
import LoadingPage from "../LoadingPage";

const Html = (props) => {
  const { title } = props;
  return (
    <div className="carta">
      <Toaster />
      <Head>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content="POS" />
        <meta property="og:title" content={title} />
      </Head>
      <div className="container-carta">{props.children}</div>
    </div>
  );
};

const PublicTemplate = (props) => {
  const {
    companyId = null,
    title,
    dialog = null,
    headActived = true,
    footerActived = true,
  } = props;

  const [openSaveUser, setOpenSaveUser] = useState(false);
  const [dataUser, setDataUser] = useState(null);

  const dataEmpresa = useEmpresa(companyId, "id");

  const getSessionUser = () => {
    const cartUser = JSON.parse(localStorage.getItem("cart_user"));

    if (
      cartUser &&
      cartUser?.numtelf_client &&
      cartUser?.numtelf_client !== ""
    ) {
      setOpenSaveUser(false);
      setDataUser(cartUser);
    } else {
      setOpenSaveUser(true);
    }
  };

  useEffect(() => {
    getSessionUser();
  }, []);

  if (dataEmpresa.load === true) {
    return (
      <Html title={title}>
        <LoadingPage open={true} />
      </Html>
    );
  }

  if (dataEmpresa.data === undefined) {
    return (
      <Html title={title}>
        <div
          style={{
            textAlign: "center",
            position: "absolute",
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "auto" }}>
            <NotFound title="No se encontró la carta" />
          </div>
        </div>
      </Html>
    );
  }

  return (
    <Html title={title}>
      {headActived && (
        <HeadCarta
          image={dataEmpresa.data.logo_emp}
          actionButtons={
            <>
              {dataUser && (
                <div className="text-black uppercase">
                  Hola {dataUser.name_client}!
                </div>
              )}
            </>
          }
        />
      )}

      {/* BODY */}
      <BodyCarta {...props}>{props.children}</BodyCarta>

      {/* FOOTER */}
      {footerActived && (
        <FooterCarta
          data={{ ...dataEmpresa.data, nom_empsede: null }}
          name={dataEmpresa.data.nom_emp}
          direction={null}
          comuna={null}
        />
      )}

      {/* DIALOG */}
      <DialogSaveUserData
        empresa={companyId}
        open={openSaveUser}
        setOpen={setOpenSaveUser}
        handleSetDataUser={setDataUser}
        isPublic={true}
      />

      {dialog}
    </Html>
  );
};

export default PublicTemplate;
