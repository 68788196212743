import ContainerInput from "../../../components/ContainerInput";

const ContainerSummary = ({ children, withBorder, ...props }) => {
  if (!withBorder)
    return (
      <div {...props} className="p-2">
        {children}
      </div>
    );

  return <ContainerInput {...props}>{children}</ContainerInput>;
};

export default ContainerSummary;
